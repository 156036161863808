import {service} from "node-back-client/src/services/api";

class CoinTransactionService {

    apiService

    constructor(url) {
        this.apiService = service(`${url}Controllers`
            ,"CoinTransaction");
    }

    getTransactions(filter,skip,limit) {
        return this.apiService.post("MyTransactions",{
            filter,skip,limit
        });
    }

    addTransactionApple(profile_id,transaction_id) {
        return this.apiService.post("AddTransaction/Apple",{
            profile_id,transaction_id
        });
    }

    remove(transaction_id) {
        return this.apiService.remove(transaction_id);
    }

    addTransaction(profile_id,transaction_id,amount){
        return this.apiService.post("AddTransaction",{
           profile_id,transaction_id,amount
        });
    }

    addReward(profile_id,amount){
        return this.apiService.post("AddReward",{
            profile_id,amount
        });
    }


}

export const coinTransactionService = CoinTransactionService;
