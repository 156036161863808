import {userService} from "./user";
import {coinTransactionService} from "./coin-transaction";
import {charlistservice,charquipmentService,serverService,profileequipmentService} from "./services";

class ApiService {
    user
    coinTransaction
    charlist
    charequipment
    servers
    profileequipment
    constructor(url) {
        this.user = new userService(url);
        this.coinTransaction = new coinTransactionService(url);
        this.charlist = new charlistservice(url);
        this.charequipment = new charquipmentService(url);
        this.servers = new serverService(url)
        this.profileequipment = new profileequipmentService(url)
    }
}


export const apiService = ApiService