import { service } from "node-back-client/src/services/api";

class UserService {
  apiService;

  constructor(url) {
    this.apiService = service(`${url}Controllers`, "Profile");
  }

  async getProfiles(filter, skip, limit) {
    let profiles = await this.apiService.post("", {
      filter,
      skip,
      limit,
    });
    let cheaterTypes = [
      "N/A",
      "Speed Hack",
      "Spammer",
      "Memory",
      "Manual",
      "Time",
    ];
    profiles.data.forEach((x) => (x.cheaterType = cheaterTypes[x.cheaterType]));
    return profiles;
  }

  async getProfilesByCharId(serverid, id) {
    try {
      let profile = await this.apiService.get(
        "Server/" + serverid + "/Characters/" + id
      );
      let cheaterTypes = [
        "N/A",
        "Speed Hack",
        "Spammer",
        "Memory",
        "Manual",
        "Time",
      ];
      profile.cheaterType = cheaterTypes[profile.cheaterType];
      return profile;
    } catch (error) {
      return [];
    }
  }
}

export const userService = UserService;
