import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      auth:true
    }
  }, {
    path: '/servers',
    name: 'Server',
    component: () => import('../views/Server.vue'),
    meta: {
      auth:true
    }
  },
  {
    path: '/accounts',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: {
      auth:true
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Faq.vue'),
    meta: {
      auth:true
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
