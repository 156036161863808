import {service} from "node-back-client/src/services/api";

class FaqService {

    apiService
    api

    constructor(url) {
        this.apiService = service(`${url}Controllers`,"Faq");
        this.api = service(`${url}`,"Faqs")
    }

    async uploadFile(faq){
        let objRet = await this.apiService.post(`video`, faq);
        return objRet;
    }

    async getVideo(filter, skip, limit= 10){

        let getVid = null;

        if(!skip){
            getVid = await this.apiService.get(`page?limit=${limit}`)
            return getVid;
        }

        getVid = await this.apiService.get(`page?count=10&offset=${skip}&limit=${limit}`);
        return getVid;
    }

    async deleteVideo(faq){
        await this.api.remove(faq.id);
        return faq;
    }
}

export const faqService = FaqService;
