import {service} from "node-back-client/src/services/api";
import {apiService} from "./apiService";
import {faqService} from "./faq";

let apis = {};
let isCalled = false;
let url = process.env.VUE_APP_REGION_URL;


async function getRegions() {
    if(isCalled) {
        return apis;
    }
    
    let serv = service(url,"RegionServers");
    let regions = await serv.get("");
    //TEMPORARY FIX OF INTERNATIONAL BECAUSE OF CHANGING THE url
    
    regions.push({
        countryCode : "internationals",
        url : "https://international.walkonlinemobile.com/api/"
    });

    
    
    regions.forEach(region => {
        apis[region.countryCode] = new apiService(region.url);
    });
    apis["region"] = new faqService(url+"/");
    return apis;
}


async function getProducts() {
    if(isCalled) {
        return apis;
    }
    let serv = service(url+"/Controllers","Product");
    let products = await serv.get("");
    return products;
}


async function getItems() {
    if(isCalled) {
        return apis;
    }
    let serv = service(url+"/CoinEquipments","");
    let products = await serv.get("");
    return products;
}
async function getItemsbyId(id) {
    if(isCalled) {
        return apis;
    }
    let serv = service(url+"/CoinEquipments","");
    let products = await serv.get(id);
    return products;
}

async function getEquipments() {
    if(isCalled) {
        return apis;
    }
    let serv = service(url+"/Equipments","");
    let products = await serv.get("");
    return products;
}

async function getEquipmentsbyId(id) {
    if(isCalled) {
        return apis;
    }
    let serv = service(url+"/Equipments","");
    let products = await serv.get(id);
    return products;
}


export const api = {
    getRegions,
    getProducts, 
    getItems,
    getItemsbyId,
    getEquipments,
    getEquipmentsbyId
}