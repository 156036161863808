import {service} from "node-back-client/src/services/api";
import {channelService} from "./channels";

let regionurl = process.env.VUE_APP_REGION_URL;
class ServersService {

    apiService

    constructor(url) {
        this.apiService = service(`${url}Controllers`
            ,"Server");
    }

    async getChannel(server) {
        let sUrl = `${server.protocol}://${server.ip}:${server.port}/api/`;
        let cs = new channelService(sUrl);
        try {
            let channels = await cs.getChannels();

            let keys = Object.keys(channels.channels);

            channels.channelList = [];
            keys.forEach(y => {
                let channel = channels.channels[y];
                channel.name = y;
                channels.channelList.push(channel);
            });
            return  channels;
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }

    getServers(){
        return this.apiService.post("");
    }

}


class charlistService {

    apiService

    constructor(url) {
        this.apiService = service(`${url}Controllers`
            ,"Profile");
    }

    getCharacters(id,serverid){
        return this.apiService.get(id+"/Server/"+serverid+"/Characters");
    }

}

class charEquipmentService {

    apiService

    constructor(url) {
        this.apiService = service(`${url}Controllers`
            ,"Character");
    }

    async getEquipments(char_id, server_id, filter={},skip=0,limit=10) {
        const equipments = await this.apiService.get(`${char_id}/Server/${server_id}/CharacterEquipments?filter=${JSON.stringify(filter)}&skip=${skip}&limit=${limit}`);
        const equipmentNamesPromises = equipments.data.map(equipment => this.getEquipmentName(equipment.equipment_id));
        const equipmentNames = await Promise.all(equipmentNamesPromises);

        equipments.data.forEach((equipment, index) => {
            equipment.equipment_name = equipmentNames[index].name
        });
        return equipments;
    }

    async getEquipmentName(equipmentId) {
        const response = await fetch(`${regionurl}/Equipments/${equipmentId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    }

    async getEquipmentList(filter){
        const response = await fetch(`${regionurl}/Equipments?filter=${filter}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    }

    async addEquipment(char_id, server_id, data){
        return await this.apiService.post(`${char_id}/Server/${server_id}/CharacterEquipments`,data);
    }

    async updateEquipment(char_id, equipment_id, server_id, data){
        return await this.apiService.patch(`${char_id}/Server/${server_id}/CharacterEquipments/${equipment_id}`,data);
    }

    async verifySkill(filter){
        const response = await fetch(`${regionurl}/Skills?filter=${filter}`);
        return response;
    }

}


class profileEquipmentService {

    apiService

    constructor(url) {
        this.apiService = service(`${url}Controllers`
            ,"ProfileEquipment");
    }

    async getProfileEquipments(id){
        const coinEquips = await this.apiService.get(`GetProfileEquipments/${id}`);
        const coinEquipsNamesPromises = coinEquips.map(cequip => this.getEquipmentName(cequip.coin_equipment_id));
        const coinEquipsNames = await Promise.all(coinEquipsNamesPromises);
        coinEquips.forEach((cequip, index) => {
            cequip.name = coinEquipsNames[index].name
          });
        return coinEquips
    }

    async getEquipmentName(cid) {
        try {
            const response = await fetch(`${regionurl}/CoinEquipments/${cid}`);
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data; // Return the equipment data
        } catch (error) {
            console.error('Error fetching equipment data:', error);
            throw error; // Re-throw the error for higher-level handling
        }
    }

    async getProfileItems(){
        try {
            const response = await fetch(`${regionurl}/CoinEquipments`);
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data; // Return the equipment data
        } catch (error) {
            console.error('Error fetching equipment data:', error);
            throw error; // Re-throw the error for higher-level handling
        }
    }

    async addProfileEquipment(data){
        return this.apiService.post("",data);
    }

}


export {
    charEquipmentService as charquipmentService,
    charlistService as charlistservice,
    ServersService as serverService,
    profileEquipmentService as profileequipmentService
}