import {service} from "node-back-client/src/services/api";

class ChannelService {

    apiService

    constructor(url) {
        this.apiService = service(`${url}Controllers`,"Channel");
    }

    async getChannels() {
        let channels = await this.apiService.get("Performance");
        return channels;
    }

}

export const channelService = ChannelService;
