<template>
  <Sidebar :router="router" :menus="menus">
    <template v-slot:title>
      <div class="sidebar-header">
        <img src="./assets/logo.png" width="50" class="d-inline-block">
        <h5 class="d-inline-block ms-4 mt-4 mb-5">Admin</h5>
      </div>

      <div class="container px-0 mb-3">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
                <label>Region</label>
                <select v-model="store.custom.countryCode" class="form-control" v-if="show"
                    @change="regionChange">
                    <option value="sel-region" selected>Select Region</option>
                    <option v-for="api in Object.keys(store.custom.apis)" v-bind:key="api" :value="api">{{api}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Server</label>
                <select v-model="store.custom.regionserver" class="form-control" v-if="show"
                    @change="regionChange">
                    <option value="sel-server" selected>Select Server</option>
                    <option v-for="server in this.servers" v-bind:key="server" :value="server._id">{{server.name}}</option>
                </select>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:header>
      <div class="float-end">
        <display-picture :is-left="true"></display-picture>
      </div>
    </template>

    <template v-slot:content>
      <div class="p-5">
        <router-view/>
        <toast-handler ></toast-handler>
        <modal-handler></modal-handler>
      </div>
    </template>
  </Sidebar>
</template>

<script>
import router from "./router";
import Sidebar from 'sidebar/src/components/Sidebar'
import DisplayPicture from 'node-back-client/src/components/DisplayPicture'
import ToastHandler from 'toast/src/components/ToastHandler'
import ModalHandler from 'modal/src/components/ModalHandler'
import {api} from "./services/api";
import store from 'node-back-client/src/store'
export default {
  data() {
    return {
      show:false,
      store:store,
      router,
      servers:{},
      menus:[{
        title:"Home",
        classNames:"fa fa-home text-info",
        href:"/"
      },{
        title:"Account Manager",
        classNames:"fa fa-user text-danger",
        href:"/accounts"
      },{
        title:"Server Manager",
        classNames:"fa fa-server text-warning",
        href:"/servers"
      },{
        title:"Database Manager",
        classNames:"fa fa-database text-success",
        submenu: [{
          title:"Preset Values",
        },{
          title:"Manager",
          submenu: [{
            title:"Preset Values",
          },{
            title:"Manager",
          }]
        }]
      },
      {
        title:"Faq",
        classNames:"fa fa-user text-primary",
        href:"/faq"
      }]
    }
  },
  methods:{
    regionChange() {
        console.log(store.custom.apis[store.custom.countryCode])
        let callbackKeys = Object.keys(store.custom.callbacks);
        callbackKeys.forEach(key => {
            store.custom.callbacks[key]();
        })
        this.getServers()
    },
    async getServers(){
        this.servers = {}
        this.servers = await store.custom.apis[store.custom.countryCode].servers.getServers();
    }
  },
  async created() {
    store.custom = {
      callbacks:{},
    };
    store.custom.apis = await api.getRegions();
    store.custom.countryCode = "sel-region";
    store.custom.regionserver = "sel-server";
    if(store.custom.callbacks.init != null)
      store.custom.callbacks.init();
    store.custom.isInit = true;
    this.show = true
  },
  components: {
    Sidebar,DisplayPicture,ToastHandler,ModalHandler
  }
}
</script>
