import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import loginService from "node-back-client/src/services/login-service";

import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

loginService.init(router, "a4853ce7-46b9-4b22-a878-cb6a45f3223a")

createApp(App).use(router).mount('#app')